body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.btn{
  font-weight: 600;
  font-size: .9rem !important;
}

.card{
  background-color: #fff !important;
    margin-bottom: 30px !important;
    border: 0px !important;
    -webkit-transition: all 0.3s ease !important;
    transition: all 0.3s ease !important;
    letter-spacing: 0.5px !important;
    border-radius: 8px !important;
    -webkit-box-shadow: 1px 5px 24px 0 rgba(68,102,242,0.05) !important;
    box-shadow: 1px 5px 24px 0 rgba(68,102,242,0.05) !important;
    background-clip: border-box !important;
    padding: 30px !important;
}
.all-package tbody tr td {
  text-align: center;
  color: #313131;
  padding: 30px 15px;
  font-size: 14px;
  min-width: 150px;
  border-bottom: 1px solid #dee2e6;
}

table svg{
  cursor: pointer;
}
.btn-danger{
background-color: #808080 !important;
color: white !important;
border-color: #808080 !important;
}

.form-check-input{
  margin: 0 !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  border: none !important;
}
.delete-btn{
  color: #000000 !important;
}
.custom-modal {
  max-width: 150%; /* Adjust this value as needed */
  margin: auto;
}
.unselectable{
  /* background-color: #ddd; */
  cursor: not-allowed;
}