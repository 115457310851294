.pull-right {
  float: right;
}

.login-img {
  width: 100%;
  border-radius: 50px;
}

.action a {
  cursor: pointer;
  margin: 2px;
}

.dropzone.dz-clickable {
  cursor: pointer;
  text-align: center;
}

.digits {
  font-family: work-Sans, sans-serif;
}

.dropzone {
  /* margin-right: auto;
  margin-left: auto;
  padding: 140px 50px; */
  border: 2px dashed var(--theme-color);
  border-radius: 10px;
  -o-border-image: none;
  border-image: none;
  background: rgba(0, 0, 0, 0);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 150px;
  position: relative;
}

.dropzone input {
  cursor: pointer;
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
}

.dropzone h4 {
  text-align: center;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 170px;
}

.grid-container {
  overflow-y: auto;
  height: 500px;
  }

 .sticky-header {
      position: sticky;
      top: -1px;
      background-color: white; 
      z-index: 1;
      background-color: #517b04 !important;
    }


/* .grid {
  position: relative;
  padding-top: 37px;
  width:100%;
  }
  .grid-container {
  overflow-y: auto;
  height: 500px;
  }
  table {
  border-spacing: 0;
  width:100%;
  }
  td + td {
  border-left:1px solid #000;
  }
  td, th {
  border-bottom:1px solid #000;
  background: #fff;
  color: #000;
  padding: 10px 25px;
  }
  th {
  height: 0;
  line-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: transparent;
  border: none;
  white-space: nowrap;
  }
  th div{
    position: absolute;
    background: transparent;
    color: #fff;
    padding: 9px 25px;
    top: 0;
    margin-left: -25px;
    line-height: normal;
    border-left: 1px solid #800;
} */
/* .feature-data{
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
} */